<template>
  <div>
    <div class="d-flex justify-content-between">
      <h1>Manage Case</h1>
      <div class="d-flex justify-content-between">
        <h1 class="mr-3">Total :{{ totalRows }}</h1>
        <h1 v-if="searchTerms != ''">Filtered results : {{ totalFilter }}</h1>
        <h1 v-else>Filtered results : 0</h1>
      </div>
    </div>
    <div class="d-flex flex-column pt-4">
      <div class="d-flex flex-column">
        <b-row class="d-flex justify-content-between pt-4 pb-2">
          <b-col class="d-flex justify-content-start">
            <b-dropdown
              v-if="selected.length > 0"
              :text="selected.length + ' is selected'"
              size="sm"
              class="mr-1"
            >
              <b-dropdown-item @click="selectAllRows"
                >Select All
              </b-dropdown-item>
              <b-dropdown-item @click="clearSelected"
                >Clear Selections
              </b-dropdown-item>
              <b-dropdown-item @click="setCloseCase"
                >Close Case
              </b-dropdown-item>
              <b-dropdown-item @click="setReopenCase"
                >Reopen Case
              </b-dropdown-item>
              <b-dropdown-item @click="deleteSelected"
                >Delete Selected
              </b-dropdown-item>
            </b-dropdown>
            <router-link
              :to="{
                name: 'audit-logs'
              }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-button
                size="sm"
                variant="secondary"
                :href="href"
                @click="navigate"
                v-b-tooltip.hover
                title="Audit Log"
              >
                <i class="fa fa-history" aria-hidden="true"></i>
              </b-button>
            </router-link>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-button variant="primary" size="sm" @click="addHandler">
              <i class="fa fa-plus mr-1"></i>Add Case
            </b-button>
            <b-form-input
              id="search_bar"
              size="sm"
              type="text"
              placeholder="Search by case number..."
              v-model="searchTerms"
              class="ml-2 w-70"
            ></b-form-input>
          </b-col>
        </b-row>

        <!-- Main table element -->
        <div class="d-flex justify-content-between">
          <b-table
            :busy="busy"
            :items="caseList"
            :fields="tableFields"
            :select-mode="selectMode"
            :filter="searchTerms"
            :filter-included-fields="filterOn"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            small
            striped
            show-empty
            sticky-header
            selectable
            stacked="md"
            class="tableBorder"
            ref="selectableTable"
            @filtered="onFiltered"
            @row-selected="onRowSelected"
          >
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(index)="data">
              {{ data.index + 1 + (currentPage - 1) * perPage }}
            </template>
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
            <!-- <template #cell(image)="row">
              <span v-if="row.item.image != undefined"
                >{{ row.item.image }}
              </span>
              <span v-else>{{ "No image available" }} </span>
            </template> -->

            <template #cell(case_number)="row">
              <div class="d-flex flex-row justify-content-start">
                <div>
                  <router-link
                    :to="{
                      name: 'case-details',
                      params: { id: row.item.id },
                      query: { parent_url: 'close-cases' }
                    }"
                    v-slot="{ href, route, navigate }"
                    custom
                    ><a :href="href" @click="navigate" class="mr-1"
                      ><u>{{ row.item.case_number }}</u></a
                    >
                  </router-link>
                </div>
                <div>
                  <div v-b-toggle="'accordion-' + row.item.id">
                    <span class="when-opened">
                      <i class="fas fa-caret-square-down"></i>
                    </span>
                    <span class="when-closed">
                      <i class="fas fa-caret-square-right"></i>
                    </span>
                  </div>
                </div>
              </div>
              <b-collapse :id="'accordion-' + row.item.id" class="mt-2">
                <span>Description : {{ row.item.description }}</span>
              </b-collapse>
            </template>
            <template #cell(priority)="row">
              {{ row.item.priority | humanizeText2 }}
            </template>
            <template #cell(status)="row">
              {{ row.item.status | humanizeText2 }}
            </template>
            <template #cell(created)="row">
              {{ row.item.created | dateTime12Hour }}
            </template>
            <template #cell(zone)="row">
              {{ row.item.zone | dashForEmptyText }}
            </template>
            <template #cell(actions)="row">
              <b-button
                class="mr-1"
                size="sm"
                title="Open Case"
                variant="secondary"
                v-b-tooltip.hover
                @click="reopenCaseHandler(row.item)"
              >
                <i class="fas fa-unlock"></i>
              </b-button>
            </template>
          </b-table>
        </div>

        <!-- pagination -->
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
            Per Page
          </div>

          <div class="d-flex">
            <b-pagination
              size="sm"
              v-if="perPage !== 'all'"
              class="d-flex"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
            ></b-pagination>
          </div>
        </div>
      </div>

      <!--Add Modal -->
      <b-modal
        id="formAddCase"
        ref="formAddCase"
        centered
        hide-footer
        :title="'Add New Case'"
        :no-close-on-backdrop="true"
        :hide-header-close="false"
      >
        <b-form @submit="onSubmitAddForm">
          <b-form-group
            id="select-group-assigned-to"
            label="Assigned to:"
            label-for="select-assigned-to"
          >
            <multiselect
              v-model="formAdd.assignedTo"
              :options="userList"
              placeholder="Please select"
              label="name"
              track-by="id"
              :allow-empty="false"
            ></multiselect>
          </b-form-group>

          <b-form-group
            id="select-group-priority"
            label="Severity:"
            label-for="select-priority"
          >
            <multiselect
              v-model="formAdd.priority"
              :options="priorityList"
              placeholder="Please select"
              label="name"
              track-by="value"
              :allow-empty="false"
            ></multiselect>
          </b-form-group>

          <b-form-group
            id="select-group-result"
            label="Result:"
            label-for="select-result"
          >
            <multiselect
              v-model="formAdd.result"
              :options="resultList"
              placeholder="Please select"
              label="name"
              track-by="value"
              :allow-empty="false"
            ></multiselect>
          </b-form-group>

          <!--          <b-form-group-->
          <!--            id="select-group-alert"-->
          <!--            label="Alert:"-->
          <!--            label-for="select-alert"-->
          <!--          >-->
          <!--            <multiselect-->
          <!--              v-model="formAdd.alert"-->
          <!--              :options="alertList"-->
          <!--              placeholder="Please select"-->
          <!--              label="name"-->
          <!--              track-by="value"-->
          <!--              :allow-empty="false"-->
          <!--            ></multiselect>-->
          <!--          </b-form-group>-->

          <b-form-group
            id="select-group-status"
            label="Status:"
            label-for="select-status"
          >
            <multiselect
              v-model="formAdd.status"
              :options="statusList"
              placeholder="Please select"
              label="name"
              track-by="value"
              :allow-empty="false"
            ></multiselect>
          </b-form-group>

          <b-form-group
            id="input-group-description"
            label-for="input-type-description"
          >
            <template v-slot:label>
              Description: <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              id="input-type"
              type="text"
              rows="5"
              v-model="$v.formAdd.description.$model"
              placeholder="Please input description....."
              aria-describedby="formAdd.description_feedback"
              :state="validateFormAdd('description')"
            ></b-form-textarea>
            <b-form-invalid-feedback
              class="invalid-feedback"
              id="formAdd.description_feedback"
            >
              This field is required.
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="text-danger" v-if="formAddErrorMessage">
            {{ formAddErrorMessage }}
          </div>
          <div class="text-right">
            <b-button type="submit" variant="primary">Submit</b-button>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import {
  required,
  email,
  minLength,
  maxLength,
  between,
  helpers
} from "vuelidate/lib/validators";
import _ from "lodash";

export default {
  components: {
    Multiselect
  },
  data: function () {
    return {
      totalRows: 0,
      totalFilter: 0,
      searchTerms: "",
      selected: [],
      busy: false,
      perPage: 0,
      currentPage: 0,
      pageOptions: [5, 10, 15],
      selectMode: "multi",
      sortBy: "case_number",
      sortDesc: true,
      // sortDirection: "asc",
      filter: null,
      filterOn: ["case_number"],
      scMode: null,
      expanded: null,
      eventType: [
        { value: "LPR", name: "SC_LPR" },
        { value: "VMS", name: "SC_VMS" },
        { value: "SC", name: "SC_DASHBOARD" }
      ],
      caseList: [],
      userList: [],
      roleList: null,
      priorityList: [
        { value: "HIGH", name: "High" },
        { value: "MEDIUM", name: "Medium" },
        { value: "LOW", name: "Low" }
      ],
      resultList: [
        { value: "PENDING_CONFIRMATION", name: "Pending Confirmation" },
        { value: "VALID_EVENT", name: "Valid Event" },
        { value: "INVALID_EVENT", name: "Invalid Event" }
      ],
      // alertList: [
      //   { value: "higest", name: "Highest" },
      //   { value: "high", name: "High" },
      //   { value: "normal", name: "Normal" },
      //   { value: "low", name: "Low" },
      //   { value: "lowest", name: "Lowest" }
      // ],
      statusList: [
        { value: "OPEN", name: "Open" },
        { value: "CLOSED", name: "Closed" }
      ],
      formAddErrorMessage: null,
      formAdd: {
        assignedTo: { value: 0, name: "-" },
        priority: { value: "MEDIUM", name: "Medium" },
        result: { value: "PENDING_CONFIRMATION", name: "Pending Confirmation" },
        alert: { value: "normal", name: "Normal" },
        status: { value: "OPEN", name: "Open" },
        description: null
      },
      tableFields: [
        {
          key: "selected",
          label: ""
        },
        {
          key: "index",
          label: "No.",
          thStyle: { width: "3%" }
        },
        /* {
          key: "image",
          label: "Image"
        }, */
        {
          key: "case_number",
          label: "Case",
          sortable: true
        },
        {
          key: "priority",
          label: "Severity",
          sortable: true
        },
        {
          key: "status",
          label: "Status",
          sortable: true
        },
        {
          key: "created",
          label: "Created",
          sortable: true
        },
        {
          key: "zone",
          label: "Zone",
          sortable: true
        },
        {
          key: "reporter_id",
          label: "Reporter",
          sortable: true
        },
        {
          key: "assignee_id",
          label: "Assigned To",
          sortable: true
        },
        { key: "actions", label: "Actions" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    }),
    getCases() {
      return this.$store.getters["case/getCases"](null);
    }
  },
  watch: {
    $route: function () {
      if (this.$route.query.per_page === "all") {
        this.currentPage = 1;
        this.perPage = this.$route.query.per_page;
      } else {
        this.currentPage = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page
          ? parseInt(this.$route.query.per_page)
          : 15;
      }
      this.getData();
    },
    getCases: function (n, o) {
      this.caseList = n;
      this.totalRows = n.length;
      console.log("this.caseList", this.caseList);
    }
  },
  validations: {
    formAdd: {
      description: { required }
    },
    formUpdate: {
      description: { required }
    }
  },
  created() {
    if (this.$route.query.per_page === "all") {
      this.currentPage = 1;
      this.perPage = this.$route.query.per_page;
    } else {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 15;
    }
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    async getData() {
      this.scMode = await this.fetchLicenseInfo();
      console.log("this.scMode", this.scMode);
      await this.fetchRolesList();
      console.log("this.roleList", this.roleList);
      await this.fetchUserList();
      console.log("this.userList", this.userList);
      this.$store.dispatch("case/resetCase");
      await this.fetchCaseList();
      console.log("this.caseList", this.caseList);
      await this.fetchPagination();
    },
    async fetchLicenseInfo() {
      let API_URL = this.getAPIServerURL + "/license/info/";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (let key in result) {
          validUrl = key;
          break outer_loop;
        }
      }

      if (result[validUrl] != undefined) {
        for (let key2 in this.eventType) {
          if (result[validUrl].sc_mode == this.eventType[key2].name) {
            return this.eventType[key2].value;
          }
        }
      }

      return [];
    },
    async fetchRolesList() {
      let API_URL = this.getAPIServerURL + "/roles/";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;

      if (result != undefined && Array.isArray(result)) {
        for (let key7 in result) {
          if (this.scMode == "VMS" && result[key7].name == "VMS Operator") {
            this.roleList = result[key7];
          }
        }
      }

      return [];
    },
    async fetchUserList() {
      let API_URL = this.getAPIServerURL + "/users/";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        for (let key3 in result) {
          validUrl = key3;
          if (result[validUrl] != undefined) {
            if (
              this.scMode == "VMS" &&
              !result[validUrl].is_superuser &&
              (result[validUrl].is_admin ||
                (result[validUrl].role_id == this.roleList.id &&
                  result[validUrl].is_active == true))
            ) {
              if (result[validUrl].name == null) {
                result[validUrl].name = result[validUrl].username;
              }

              result[validUrl].name = _.startCase(result[validUrl].name);
              this.userList.push(result[validUrl]);
            }

            if (
              this.scMode != "VMS" &&
              !result[validUrl].is_superuser &&
              result[validUrl].is_active == true
            ) {
              if (result[validUrl].name == null) {
                result[validUrl].name = result[validUrl].username;
              }

              result[validUrl].name = _.startCase(result[validUrl].name);
              this.userList.push(result[validUrl]);
            }
          }
        }
      }

      return [];
    },
    async fetchCaseList() {
      let API_URL = this.getAPIServerURL + "/api/caseservice/case/all/";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result !== undefined) {
            outer_loop: for (var key4 in result) {
              validUrl = key4;
              break outer_loop;
            }
          }
          if (result[validUrl] != undefined) {
            for (let key5 in result[validUrl]) {
              for (let key6 in this.userList) {
                if (
                  result[validUrl][key5].assignee_id == this.userList[key6].id
                ) {
                  let name = null;
                  if (this.userList[key6].name != null) {
                    name = this.userList[key6].name;
                  } else {
                    name = this.userList[key6].username;
                  }
                  result[validUrl][key5].assignee_id = name;
                }

                if (
                  result[validUrl][key5].reporter_id == this.userList[key6].id
                ) {
                  let name = null;
                  if (this.userList[key6].name != null) {
                    name = this.userList[key6].name;
                  } else {
                    name = this.userList[key6].username;
                  }
                  result[validUrl][key5].reporter_id = name;
                }
              }

              if (result[validUrl][key5].status == "CLOSED") {
                this.$store.dispatch("case/addCase", result[validUrl][key5]);
              }
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    async fetchPagination() {
      let query = {};
      if (this.perPage === "all") {
        query = null;
      } else {
        query = {
          page_index: this.currentPage,
          page_size: this.perPage,
          event_type: this.scMode
        };
      }

      let API_URL = this.getAPIServerURL + "/api/caseservice/case/all/";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL, query)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result !== undefined) {
            outer_loop: for (var key4 in result) {
              validUrl = key4;
              break outer_loop;
            }
          }
          if (result[validUrl] != undefined) {
            /* if (result[validUrl]["total"] !== undefined) {
              this.totalRows = parseInt(result[validUrl]["total"]);
            } */
            if (result[validUrl]["page"] !== undefined) {
              this.currentPage = parseInt(result[validUrl]["page"]);
            }

            if (result[validUrl]["size"] !== undefined) {
              this.perPage = parseInt(result[validUrl]["size"]);
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    async fetchCaseByCaseID(item) {
      let query = {
        case_id: item.case_id,
        case_number: item.case_number
      };

      let API_URL = this.getAPIServerURL + "/api/caseservice/case/?";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL, query);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key4 in result) {
          validUrl = key4;
          break outer_loop;
        }
      }

      if (result[validUrl] != undefined) {
        for (let key6 in this.userList) {
          if (result[validUrl].assignee_id == this.userList[key6].id) {
            let name = null;
            if (this.userList[key6].name != null) {
              name = this.userList[key6].name;
            } else {
              name = this.userList[key6].username;
            }
            result[validUrl].assignee_id = name;
          }

          if (result[validUrl].reporter_id == this.userList[key6].id) {
            let name = null;
            if (this.userList[key6].name != null) {
              name = this.userList[key6].name;
            } else {
              name = this.userList[key6].username;
            }
            result[validUrl].reporter_id = name;
          }
        }
        return result[validUrl];
      }

      return [];
    },

    onRowSelected(items) {
      this.selected = items;
    },
    // select list table
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    setCloseCase() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to update the status of the selected objects to close?",
          {
            centered: true
          }
        )
        .then(async (value) => {
          if (value) {
            for (let index1 in this.selected) {
              let data = await this.onSubmitCloseCase(this.selected[index1]);
              if (data == null) {
                this.$bvModal
                  .msgBoxOk(
                    this.selected.length +
                      " Case(s) has been updated successfully",
                    {
                      centered: true
                    }
                  )
                  .then((value) => {
                    if (value) {
                      location.reload();
                    }
                  });
              } else {
                this.$bvModal
                  .msgBoxOk(data + " Case(s) has been updated successfully", {
                    centered: true
                  })
                  .then((value) => {
                    if (value) {
                      location.reload();
                    }
                  });
              }
            }
          } else {
            // revert to previous state
            //console.log("revert to previous state");
            this.getData();
          }
        })
        .catch((err) => {
          // An error occurred
          console.log("err", err);
        });
    },
    async onSubmitCloseCase(item) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/caseservice/case/close/?case_id=" +
        item.id +
        "&case_number=" +
        item.case_number;
      let count = 0;

      if (item.status == "CLOSED") {
        return count;
      } else {
        const client = this.$root.getAjaxFetchClient();

        let res = await client.postRequest(API_URL);

        let result = res.result;
        let error = res.detail;
        let validUrl = "";

        if (error != undefined) {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: error,
            message_type: "danger"
          });
        } else {
          if (result != undefined) {
            outer_loop: for (let key13 in result) {
              if (result != undefined) {
                validUrl = key13;
                break outer_loop;
              }
            }
          }
          if (result[validUrl] == null) {
            return result[validUrl];
          }
        }
      }

      return [];
    },
    setReopenCase() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to update the status of the selected objects to open?",
          {
            centered: true
          }
        )
        .then(async (value) => {
          if (value) {
            for (let index2 in this.selected) {
              let data = await this.onSubmitOpenCase(this.selected[index2]);
              if (data == null) {
                this.$bvModal
                  .msgBoxOk(
                    this.selected.length +
                      " Case(s) has been updated successfully",
                    {
                      centered: true
                    }
                  )
                  .then((value) => {
                    if (value) {
                      location.reload();
                    }
                  });
              } else {
                this.$bvModal
                  .msgBoxOk(data + " Case(s) has been updated successfully", {
                    centered: true
                  })
                  .then((value) => {
                    if (value) {
                      location.reload();
                    }
                  });
              }
            }
          } else {
            // revert to previous state
            //console.log("revert to previous state");
            this.getData();
          }
        })
        .catch((err) => {
          // An error occurred
          console.log("err", err);
        });
    },
    async onSubmitOpenCase(item) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/caseservice/case/open/?case_id=" +
        item.id +
        "&case_number=" +
        item.case_number;
      let count = 0;

      if (item.status == "OPEN") {
        return count;
      } else {
        const client = this.$root.getAjaxFetchClient();

        let res = await client.postRequest(API_URL);

        let result = res.result;
        let error = res.detail;
        let validUrl = "";

        if (error != undefined) {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: error,
            message_type: "danger"
          });
        } else {
          if (result != undefined) {
            outer_loop: for (let key25 in result) {
              if (result != undefined) {
                validUrl = key25;
                break outer_loop;
              }
            }
          }
          if (result[validUrl] == null) {
            return result[validUrl];
          }
        }
      }

      return [];
    },
    deleteSelected() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete the selected objects?",
          {
            centered: true
          }
        )
        .then(async (value) => {
          if (value) {
            for (let index3 in this.selected) {
              let data = await this.onSubmitDeleteCase(this.selected[index3]);
              if (data == null) {
                this.$bvModal
                  .msgBoxOk(
                    this.selected.length +
                      " Case(s) has been deleted successfully",
                    {
                      centered: true
                    }
                  )
                  .then((value) => {
                    if (value) {
                      location.reload();
                    }
                  });
              }
            }
          } else {
            // revert to previous state
            //console.log("revert to previous state");
            this.getData();
          }
        })
        .catch((err) => {
          // An error occurred
          console.log("err", err);
        });
    },
    async onSubmitDeleteCase(item) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL + "/api/caseservice/case/?case_id=" + item.id;

      const client = this.$root.getAjaxFetchClient();

      let res = await client.deleteRequest(API_URL);

      let result = res.result;
      let error = res.detail;
      let validUrl = "";

      if (error != undefined) {
        this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text: error,
          message_type: "danger"
        });
      } else {
        if (result != undefined) {
          outer_loop: for (let key11 in result) {
            if (result != undefined) {
              validUrl = key11;
              break outer_loop;
            }
          }
        }
        if (result[validUrl] == null) {
          return result[validUrl];
        }
      }

      return [];
    },

    //handle pagination
    handlePageChange(page) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      if (pageSize === "all") {
        this.$router.push({
          ...this.$route,
          query: {
            per_page: pageSize
          }
        });
      } else {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            per_page: parseInt(pageSize)
          }
        });
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.totalFilter = filteredItems.length;
      this.currentPage = 1;
    },
    addHandler() {
      this.formAdd.assignedTo = { value: 0, name: "-" };
      this.formAdd.priority = { value: "MEDIUM", name: "Medium" };
      this.formAdd.result = {
        value: "PENDING_CONFIRMATION",
        name: "Pending Confirmation"
      };
      this.formAdd.alert = { value: "normal", name: "Normal" };
      this.formAdd.status = { value: "OPEN", name: "Open" };
      this.formAdd.description = null;

      this.$v.$reset();
      this.$bvModal.show("formAddCase");
    },
    reopenCaseHandler(item) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to reopen the Case?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            this.onSubmitReOpenCase(item);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    onSubmitReOpenCase(item) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/caseservice/case/open/?case_id=" +
        item.id +
        "&case_number=" +
        item.case_number;

      const client = this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL)
        .then(async (data) => {
          if (data.detail != undefined) {
            console.log("data_error", data.detail);
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: data.detail,
              message_type: "danger"
            });
          } else {
            let result = data.result;
            let validUrl = "";

            if (result != undefined) {
              outer_loop: for (let key7 in result) {
                if (result[key7] != undefined) {
                  validUrl = key7;
                  break outer_loop;
                }
              }
            }
            if (result[validUrl] == null) {
              console.log("result[validUrl]", result[validUrl]);

              this.$store.dispatch("case/updateCase", {
                id: item.id,
                data: {
                  case_number: item.case_number,
                  assignedTo: item.assignedTo,
                  priority: item.priority,
                  result: item.result,
                  alert: item.alert,
                  status: item.status,
                  description: item.description
                }
              });

              this.$bvModal
                .msgBoxOk("Case has been reopened", {
                  centered: true
                })
                .then((value) => {
                  if (value) {
                    location.reload();
                  }
                });
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },

    validateFormAdd(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.formAdd[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    onSubmitValidationAddForm() {
      let $this = this;
      $this.$v.formAdd.$touch();
      return $this.$v.formAdd.$anyError;
    },
    onSubmitAddForm: function (e) {
      e.preventDefault();
      let $this = this;
      let anyError = $this.onSubmitValidationAddForm();
      if (!anyError) {
        let createCase = {};
        createCase["event_type"] = $this.scMode;
        createCase["assignee_id"] = $this.formAdd.assignedTo.id;
        createCase["priority"] = $this.formAdd.priority.value;
        createCase["result"] = $this.formAdd.result.value;
        createCase["alert"] = $this.formAdd.alert.value;
        createCase["status"] = $this.formAdd.status.value;
        createCase["description"] = $this.formAdd.description;

        console.log("createCase", createCase);

        let API_URL = $this.getAPIServerURL + "/api/caseservice/case/";
        const client = $this.$root.getAjaxFetchClient();
        client
          .postRequest(API_URL, createCase)
          .then(async (data) => {
            if (data.detail != undefined) {
              console.log("data_error_add", data.detail);
              this.formAddErrorMessage = data.detail;
            } else {
              let result = data.result;
              let validUrl = "";

              if (result != undefined) {
                outer_loop: for (let key8 in result) {
                  if (result[key8] != undefined) {
                    validUrl = key8;
                    break outer_loop;
                  }
                }
              }
              if (result[validUrl] != undefined) {
                console.log("result[validUrl]", result[validUrl]);
                if (createCase["status"] == "OPEN") {
                  let API_URL =
                    $this.getAPIServerURL +
                    "/api/caseservice/case/open/?case_id=" +
                    result[validUrl].case_id +
                    "&case_number=" +
                    result[validUrl].case_number;
                  const client = $this.$root.getAjaxFetchClient();
                  client.postRequest(API_URL).then(async (data) => {
                    if (data.detail != undefined) {
                      console.log("data_error_add", data.detail);
                      this.formAddErrorMessage = data.detail;
                    } else {
                      let caseData = await this.fetchCaseByCaseID(
                        result[validUrl]
                      );
                      this.$store.dispatch("case/addCase", caseData);
                      this.$bvModal.hide("formAddCase");
                      this.$router.push({
                        name: "open-cases"
                      });
                      this.$store.dispatch("session/addGlobalAlertMessage", {
                        message_text: "Case has been added successfully",
                        message_type: "success"
                      });
                    }
                  });
                }
                if (createCase["status"] == "CLOSED") {
                  let API_URL =
                    $this.getAPIServerURL +
                    "/api/caseservice/case/close/?case_id=" +
                    result[validUrl].case_id +
                    "&case_number=" +
                    result[validUrl].case_number;
                  const client = $this.$root.getAjaxFetchClient();
                  client.postRequest(API_URL).then(async (data) => {
                    if (data.detail != undefined) {
                      console.log("data_error_add", data.detail);
                      this.formAddErrorMessage = data.detail;
                    } else {
                      let caseData = await this.fetchCaseByCaseID(
                        result[validUrl]
                      );
                      this.$store.dispatch("case/addCase", caseData);
                      this.$bvModal.hide("formAddCase");
                      this.$store.dispatch("session/addGlobalAlertMessage", {
                        message_text: "Case has been added successfully",
                        message_type: "success"
                      });
                    }
                  });
                }
              }
            }
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
          });
      } else {
        // $this.$bvModal.msgBoxOk("Form not completed!", {
        //   centered: true
        // });
        return false;
      }
    }
  }
};
</script>
<style lang="scss">
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.text-danger {
  color: #d53e4c !important;
  font-size: small;
  font-weight: bold;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
